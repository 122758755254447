import React from 'react';
import Button from "../Button/Button"
import {ContactStyles} from "./ContactStyles"
import {useForm, ValidationError} from '@formspree/react';
import {useTranslation} from 'react-i18next';
import '../../components/i18n'; // Importing i18n configuration

function Contact() {
    const { t} = useTranslation();

    const [state, handleSubmit] = useForm("xwkgykly");
    if (state.succeeded) {
        return (
            <ContactStyles className="section">
                <p>{ t('contactSuccessful') }</p>
            </ContactStyles>
        );
    }


    return (
        <ContactStyles className="section">
            <form name="contact" onSubmit={handleSubmit}>
                <input id="name" type="text" name="name" placeholder={t('contactName')} />

                <input id="email" name="email" type="email" placeholder="Email" />
                <ValidationError prefix="Email" field="email" errors={state.errors} className="form-error"/>

                <textarea id="message" name="message" defaultValue={ t('contactMessage') } rows="5" />
                <ValidationError field="message" errors={state.errors} className="form-error"/>

                <Button text={t('contactSubmit')} type="submit" disabled={state.submitting}/>
            </form>
        </ContactStyles>
    );
}

export default Contact
