import * as React from "react"
import Layout from "../components/Layout"
import {StaticImage} from "gatsby-plugin-image"
import Contact from "../components/Contact/Contact"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import {useTranslation} from "react-i18next";

const ContactPage = () => {
    const { t} = useTranslation();

    return (
        <>
            <Seo title="Contact"/>
            <Layout>
                <SimpleBanner title={t("contactTitle")}>
                    <StaticImage
                        className="banner__image"
                        src="../../static/macbook-dark.webp"
                        alt="Apple Macbook Dark"
                    />
                </SimpleBanner>
                <Contact/>
            </Layout>
        </>
    )
}

export default ContactPage
