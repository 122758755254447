import styled from "styled-components"

export const ContactStyles = styled.section`
  max-width: 750px;
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;

  input {
    height: calc(var(--gap) * 2);
    margin-bottom: var(--gap);
  }

  input,
  textarea {
    background-color: #000;
    color: #fff;
    border: none;
    outline: 2px solid rgba(255, 255, 255, 0.15);
    border-radius: 6px;
    width: 100%;
    font-size: var(--p);
    font-weight: 700;
    font-family: "Heebo", sans-serif;
    padding: 15px;
    transition: outline 0.3s ease;

    &:focus {
      outline: 2px solid var(--primary);
    }

    &::-webkit-input-placeholder {
      color: var(--inActive);
    }

    &::-moz-placeholder {
      color: var(--inActive);
    }

    &:-ms-input-placeholder {
      color: var(--inActive);
    }

    &:-moz-placeholder {
      color: var(--inActive);
    }
  }
  textarea {
    margin-bottom: var(--gap);
  }

    .form-error {
        color: #d32f2f; /* Red color for visibility */
        background-color: #f8d7da; /* Light red/pink background */
        border-color: #f5c2c7; /* Slightly darker border for visibility */
        border-left: 5px solid #f44336; /* Solid left border for emphasis */
        padding: 10px 20px; /* Padding for spacing */
        margin-top: 8px; /* Space above the error message */
        border-radius: 4px; /* Rounded corners */
        font-size: 0.9em; /* Slightly smaller font size */
        width: 100%; /* Full width to cover the container */
        box-sizing: border-box; /* Includes padding and border in the width */
    }

    input:invalid, textarea:invalid {
        border: 2px solid #d32f2f; /* Red border for invalid inputs */
    }

    input:focus:invalid, textarea:focus:invalid {
        outline: none; /* Removes outline */
        box-shadow: 0 0 5px #f44336; /* Adds a red glow effect */
    }
`
